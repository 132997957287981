.contactsimagecontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 100%;
  margin: 20px auto;
  margin-bottom: 60px;
}
  
#headercontainercontacts {
  display: inline-block;
  text-align: center;
  margin-bottom: 0;
}

.clickablelogo {
  max-width: 125px;
  max-height: 125px;
}

.contactsimagecontainer a {
  display: inline-block;
  width: calc(20% - 10px);
  height: auto;
  max-height: 175px;
  max-width: 175px;
  margin: 0 auto;
  vertical-align: middle;
}

#contactssection {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max(40vh, 500px);
  background-color: #2e2d2d;
}

.contactsimagecontainer a img {
  width: 100%;
  height: 100%;
}

.animateddiv {
    position: relative;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.4s, transform 0.4s;
  }
  
  .animateddiv.active {
    opacity: 1;
    transform: translateY(0);
  }

  .animateddiv.hidden {
    opacity: 0;
    transform: translateY(20px);
  }
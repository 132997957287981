
.typewriter-header {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .typewriterSmall h2.hidden {
    visibility: hidden;
  }

  /*The animation here works by having a h1 that is revealed slowly, this takes about 3 seconds,
  once that is done, there is a delayed second animation, where the h2 is invisible by default, and then
  is made visible and animated in, otherwise it just appears as visible and then animates and looks trash*/
  /*DESKTOP*/
  @media only screen and (min-width: 1024px) {
    .typewriter h1 {
      overflow: hidden; /* Ensures the content is not revealed until the animation */
      border-right: .15em solid orange; /* The typwriter cursor */
      white-space: nowrap; /* Keeps the content on a single line */
      margin: 0 auto; /* Gives that scrolling effect as the typing happens */
      letter-spacing: .15em; /* Adjust as needed */
      color: rgb(0,255,0);
      background: fixed;
      background-color: grey;
      font-family: "brandon-grotesque", brandon, martel-sans, helvetica, sans-serif;
      animation: 
        typing 2s steps(12, end),
        blink-caret-and-end 1.5s step-end 2 forwards;
    }

    .typewriterSmall h2 {
      overflow: hidden; 
      border-right: .15em solid orange; 
      white-space: nowrap; 
      margin: 0 auto;
      letter-spacing: .15em; 
      color: rgb(0,255,0);
      background: fixed;
      background-color: grey;
      font-family: "brandon-grotesque", brandon, martel-sans, helvetica, sans-serif;
      font-size: 1em;
      visibility: hidden;
      animation: typing-with-visibility 3s steps(26, end), blink-caret 1.5s step-end infinite;
      animation-delay: 3s;
      animation-fill-mode: forwards; 
    }
  }

  @media only screen and (max-width: 1024px) {
    .typewriter h1 {
      overflow: hidden; 
      border-right: .15em solid orange; 
      white-space: nowrap; 
      margin: 0 auto;
      letter-spacing: .15em; 
      color: rgb(0,255,0);
      background: fixed;
      background-color: grey;
      font-family: "brandon-grotesque", brandon, martel-sans, helvetica, sans-serif;
      animation: 
        typing 2s steps(12, end),
        blink-caret-and-end 1.5s step-end 2 forwards;
    }

    .typewriterSmall h2 {
      overflow: hidden; 
      border-right: .15em solid orange;
      white-space: nowrap; 
      margin: 0 auto; 
      letter-spacing: .15em; 
      color: rgb(0,255,0);
      background: fixed;
      background-color: grey;
      font-family: "brandon-grotesque", brandon, martel-sans, helvetica, sans-serif;
      font-size: 1em;
      visibility: hidden;
      animation: typing-with-visibility 3s steps(26, end), blink-caret 1.5s step-end infinite;
      animation-delay: 3s;
      animation-fill-mode: forwards; 
    }
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typing effect */
  @keyframes typing-with-visibility {
    from { width: 0;
      visibility: visible;
    }
    to { width: 100%;
      visibility: visible;
    }
  }

  /* The typewriter cursor effect */
  @keyframes blink-caret-and-end {
    from, to { border-color: transparent }
    50% { border-color: orange; }
    100% {
      border-color: transparent;
    }
  }


  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }
  
@media only screen and (max-width: 1024px) {
    .resumeentry {
        border: solid 1px #555;
        box-shadow: 6px -6px 3px  rgba(0,0,0,0.6);
        -moz-box-shadow: 6px -6px 3px  rgba(0,0,0,0.6);
        -webkit-box-shadow: 6px -6px 3px  rgba(0,0,0,0.6);
        -o-box-shadow: 6px -6px 3px  rgba(0,0,0,0.6);
        border-radius:5px;
        margin: 5px;
        margin-left: 5%;
        margin-right: 5%;
        width: 90%;
    }

    .resumeentryimagetypeemoji{
        max-width: 100px;
        max-height: 50px;
        padding-right: 10px;
    }
    
    .resumeentryimagecontainer{
        display: block;
    }
    
    .resumeprojectwinner{
        text-align: center;
        color:gold;
    }
    
    .resumeemoji{
        font-size: larger;
    }
    
    .resumeentryimage {
        padding: 20px;

        align-self: flex-start;
    }
    
    .resumeentrytitle{
        display: inline-block;
        text-align: center;
        min-width: 100%;
    }

    .resumetextcontainer{
        flex-direction: column;
        text-align: left;
    }
    
    .resumeentrydate{
        font-style: italic;
        text-align: left;
        padding-left: 25px;
        color: rgb(0,255,0);
    }
    
    .resumeentrytext {
        padding-left: 15px;
        margin: 10px;
        flex: 1;
    }

    .resumeentrytext li {
        margin-bottom: 5px;
    }
}

/* Set the background color of the page to black */
body {
    background-color: #131516;
  }
  
  /* Set the color of all text to a light color, such as white */
  body {
    color: #fff;
  }
  
  /* Set the color of links to a light color, such as light blue */
  a {
    color: #3391ff;
  }
  
  /* Set the color of visited links to a lighter color, such as sky blue 
  a:visited {
    color: #8a2be2;
  } */
  
  /* Set the color of headings to a light color, such as white */
  h1, h2, h3, h4, h5, h6 {
    color: #fff;
  }
  
  /* Set the background color of inputs and textareas to dark gray */
  input, textarea {
    background-color: #333;
    color: #fff;
  }
  
  /* Set the border color of inputs and textareas to a lighter color, such as gray */
  input, textarea {
    border-color: #ccc;
  }
  
  /* Set the color of buttons to a light color, such as white, and the background color to a dark color, such as gray */
  button {
    color: #fff;
    background-color: #333;
  }
  
  /* Set the color of selected text to a light color, such as white */
  ::selection {
    background-color: #004daa;
    color: #e8e6e3;
  }
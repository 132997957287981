
.paddedimg{
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    height: 30px; 
    padding: 5px;
  }
  
  .paddedcarouselimg{
    margin: auto;
  }
@media only screen and (min-width: 1024px) {

.resumeentry {
    border: solid 1px #555;
    box-shadow: 10px -10px 5px  rgba(0,0,0,0.6);
    -moz-box-shadow: 10px -10px 5px  rgba(0,0,0,0.6);
    -webkit-box-shadow: 10px -10px 5px  rgba(0,0,0,0.6);
    -o-box-shadow: 10px -10px 5px  rgba(0,0,0,0.6);
    border-radius:25px;
    margin: 25px;
    margin-left: 22.5%;
    margin-right: 22.5%;
    display: flex;
}


.resumeentryimagetypecontainer{
    width: 178px;
    height: 37px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.resumeentryimagetypeemoji{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    padding-right: 10px;
}

.resumeentryimagecontainer{
    display: block;
}

.resumeprojectwinner{
    color:gold;
}

.resumeemoji{
    font-size: larger;
}

.resumeentryimage {
    padding: 20px;
    margin-left: 15px;
    margin-right: 30px;
    align-self: flex-start;
}

.resumeentrytitle{
    padding-left: 0px;
    flex: 1;
}

.resumetextheader {
    display: flex;
    align-items: flex-start;
}

.resumetextcontainer{
    flex-direction: column;
    text-align: left;
}

.resumeentrydate{
    font-style: italic;
    text-align: left;
    color: rgb(0,255,0);
}

.resumeentrytext {
    margin-right: 12px;
    flex: 1;
}

.resumeentrytext li {
    margin-bottom: 5px;
}
  
}


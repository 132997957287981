.App {
  text-align: center;
}

.defaulttext{
  font-size: 20px;
}

.blockcontainer{
  display: block;
min-width: 80%;
}


.defaultlarge{
  font-size: 30px;
  font-weight: bold;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.defaultlargeWithBanner{
  font-size: 30px;
  font-weight: bold;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
  position: relative;
}



.App-link {
  color: #61dafb;
}



@media only screen and (min-width: 1024px) {
.middleContainerLarge{
  padding-left: 22.5%;
  padding-right: 22.5%;
}
.middleContainer{
  padding-left: 30%;
  padding-right: 30%;
}

.defaultlargeWithBanner:before {
  content: "";
  display: block;
  width: 130px;
  height: 5px;
  background: #808080;
  left: 0;
  top: 50%;
  position: absolute;
  opacity: 1; 
}

.defaultlargeWithBanner:after {
  content: "";
  display: block;
  width: 130px;
  height: 5px;
  background: #808080;
  right: 0;
  top: 50%;
  position: absolute;
  opacity: 1; 
 }

.backgroundimage {
  background-image: url("../assets/LandingImage.jpg");
  --darkreader-inline-bgcolor: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  background-position: 50% 50%; 
  min-height: 90vh;
  background-attachment: fixed;
}
}

@media only screen and (max-width: 1024px) {
  .middleContainerLarge{
    padding-left: 5%;
    padding-right: 5%;
  }

  .middleContainer{
    padding-left: 7%;
    padding-right: 7%;
  }

  .backgroundimage {
    background-image: url("../assets/landingimagemobile.png");
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: hidden;
    overflow: scroll;
  }
}

section {
  /*height: 100vh;*/
  /*background-color: red;*/
}

#introSection {
  height: 90vh;
}


#end {
  background-color: transparent;
}

/* Turn off parallax scrolling for all tablets and phones. Increase/decrease the pixels if needed */
@media only screen and (max-device-width: 1366px) {
  .parallax {
    background-attachment: scroll;
  }
}